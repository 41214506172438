import { Tentacle } from "./Tentacle";

const frame_positions = {
  72: { x: -1.7321655273437493, y: 9.48956179151348 },
  73: { x: -1.7321655273437493, y: 9.48956179151348 },
  74: { x: -1.7321655273437493, y: 9.48956179151348 },
  75: { x: -1.7321655273437493, y: 9.48956179151348 },
  76: { x: -1.4107849121093743, y: 10.11971986060049 },
  77: { x: -0.1252593994140625, y: 12.797894646139707 },
  78: { x: 2.4457885742187493, y: 16.263770009957113 },
  79: { x: 6.141671752929689, y: 20.98996151194853 },
  80: { x: 11.926531982421874, y: 26.188774557674634 },
  81: { x: 19.478988647460938, y: 31.07250856885723 },
  82: { x: 29.441802978515625, y: 35.641163545496326 },
  83: { x: 40.20807113647461, y: 38.319338331035546 },
  84: { x: 52.58124618530273, y: 38.949496400122555 },
  85: { x: 64.63303833007812, y: 37.37410122740503 },
  86: { x: 76.52413940429688, y: 33.278067794500615 },
  87: { x: 86.0048828125, y: 26.50385957605699 },
  88: { x: 93.39664916992187, y: 19.572111840341606 },
  89: { x: 98.37805786132813, y: 12.010203043619793 },
  90: { x: 100.46703491210937, y: 6.181231928806682 },
  91: { x: 100.78841552734374, y: 2.872897578220744 },
  92: { x: 99.34219970703126, y: 1.9276589786305145 },
  93: { x: 96.28907775878906, y: 3.660596660539216 },
  94: { x: 92.7538848876953, y: 7.441551058900124 },
  95: { x: 87.45109558105469, y: 12.482821595435052 },
  96: { x: 80.22001953125, y: 18.154253192976412 },
  97: { x: 72.34618225097657, y: 22.722905177696084 },
  98: { x: 62.704748535156256, y: 26.66139909332874 },
  99: { x: 53.545385742187506, y: 29.02449484432445 },
  100: { x: 43.74326095581055, y: 29.81219243068321 },
  101: { x: 36.030113220214844, y: 29.49711339613971 },
  102: { x: 28.959729003906247, y: 28.07925474877451 },
  103: { x: 24.62108459472656, y: 26.503856584137566 },
  104: { x: 21.567962646484375, y: 25.243537454044123 },
  105: { x: 19.63967590332031, y: 24.14075784122243 },
  106: { x: 18.99691467285156, y: 22.88043871112899 },
  107: { x: 19.63967590332031, y: 21.62011958103554 },
  108: { x: 21.728652954101562, y: 20.674882477405028 },
  109: { x: 24.460392761230466, y: 20.044724408318018 },
  110: { x: 28.316966247558597, y: 18.469326243681067 },
  111: { x: 33.13768310546875, y: 17.681628657322307 },
  112: { x: 37.95839996337891, y: 17.209010105507048 },
  113: { x: 42.61842651367188, y: 16.578852036420038 },
  114: { x: 47.27845306396485, y: 16.263773001876537 },
  115: { x: 50.65295524597168, y: 16.263773001876537 },
  116: { x: 53.8667667388916, y: 15.160993389054841 },
  117: { x: 55.955744171142584, y: 12.955437155330884 },
  118: { x: 56.91988754272461, y: 8.386785170611216 },
  119: { x: 56.598506164550784, y: 3.818133185891547 },
  120: { x: 54.34883804321289, y: -1.8532984116498152 },
  121: { x: 50.97433586120606, y: -7.3671897439395675 },
  122: { x: 46.7963809967041, y: -11.778302585377412 },
  123: { x: 42.618426132202146, y: -14.771557901419845 },
  124: { x: 39.88668670654297, y: -17.292193917667163 },
  125: { x: 39.08323364257812, y: -19.3402110081093 },
  126: { x: 37.73278274536133, y: -22.919728447409238 },
  127: { x: 36.073744201660155, y: -26.76318479051777 },
  128: { x: 35.26352996826172, y: -28.55819881663603 },
  129: { x: 35.26352996826172, y: -28.55819881663603 },
  130: { x: 35.26352996826172, y: -32 },
};

export const animate_tentacles_grabbed_donut = ({
  donut,
  time,
  x,
  y,
}: Tentacle) => {
  if (donut === null) {
    return;
  }
  const frame = Math.min(130, Math.floor(time * 15));
  const frame_position = frame_positions[frame];
  if (frame_position === undefined) {
    return;
  }
  donut.x = frame_position.x;
  donut.y = frame_position.y;
};
